import React, { useState } from 'react';
import { Modal } from 'react-responsive-modal';
import { Link } from './Link';
import 'react-responsive-modal/styles.css';
import './CartClear.scss';

const CartClear = ({ show }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      {show && <span className="icon icon-cancel cart-clear-button" onClick={() => setOpen(true)} />}
      <Modal
        center
        open={open}
        styles={{
          modal: { width: '550px', padding: 10, borderRadius: '10px' },
          closeButton: { cursor: 'pointer', marginLeft: '6px' },
        }}
        onClose={() => setOpen(false)}
        showCloseIcon={false}
      >
        <div className="cart-clear-modal">
          <h3 className="cart-clear-modal-title">Are you sure you want to clear the cart?</h3>
          <div className="cart-clear-modal-buttons">
            <button type="button" className="button grey" onClick={() => setOpen(false)}>
              No
            </button>
            <Link className="button" to="https://app.yordar.com.au/cart/clear">
              Yes
            </Link>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CartClear;
