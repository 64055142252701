import React from 'react';
import { Logo } from './Logo';
import { Link } from './Link';
import { Newsletter } from './Newsletter';
import { SocialLinks } from './SocialLinks';
import { footer as footerNav } from '../../config/navigations';
import './Footer.scss';

const FooterNavigationLink = ({ className, to, text }) => (
  <li>
    <Link className={className} to={to}>
      {text}
    </Link>
  </li>
);

export const Footer = () => (
  <footer className="footer">
    <div className="wrapper">
      <section className="footer-links">
        <div className="footer-links-col">
          <nav className="footer-links-nav">
            <h5>
              <Link to="/office-catering">Office Catering</Link>
            </h5>
            <ul className="footer-links-list">
              {footerNav.catering.map(link => (
                <FooterNavigationLink key={link.to + link.icon} {...link} />
              ))}
            </ul>
          </nav>
        </div>
        <div className="footer-links-col">
          <nav className="footer-links-nav">
            <h5>
              <Link to="/kitchen-and-pantry">Kitchen & Pantry</Link>
            </h5>
            <ul className="footer-links-list">
              {footerNav.pantry.map(link => (
                <FooterNavigationLink key={link.to + link.icon} {...link} />
              ))}
            </ul>
          </nav>
        </div>
        <div className="footer-links-col something-else">
          <nav className="footer-links-nav">
            <h5>Something Else?</h5>
            <ul className="footer-links-list">
              {footerNav.somethingElse.map(link => (
                <FooterNavigationLink key={link.to + link.icon} {...link} />
              ))}
            </ul>
          </nav>
          <nav className="footer-links-nav about-help">
            <h5>About / Help</h5>
            <ul className="footer-links-list">
              {footerNav.help.map(link => (
                <FooterNavigationLink key={link.to + link.icon} {...link} />
              ))}
            </ul>
          </nav>
        </div>
        <div className="footer-links-col footer-locations">
          <nav className="footer-links-nav">
            <h5>Where we Deliver</h5>
            <ul className="footer-links-list">
              {footerNav.locations.map(link => (
                <FooterNavigationLink key={link.to + link.icon} {...link} />
              ))}
            </ul>
          </nav>
        </div>
      </section>
      <section className="footer-right">
        <Logo theme="dark" />
        <Newsletter />
        <SocialLinks />
        <div className="footer-sitemap">
          <nav className="footer-sitemap-nav">
            <Link to="/terms">Terms & Privacy</Link>
            <Link to="/sitemap">Sitemap</Link>
          </nav>
          <span className="footer-sitemap-legal">
            © {new Date().getFullYear()} Your Order PTY LTD. All rights reserved.
          </span>
        </div>
      </section>
    </div>
  </footer>
);
