import React from 'react';
import Helmet from 'react-helmet';
import { Header, Footer, SEO } from 'components';
import { UserProvider } from '../context/user';
import LogRocket from './LogRocket';
import 'typeface-lato';
import '../sass/global/styles.scss';
import './Layout.scss';
import setupHubspotChat from '../../api/hubspot';

export const Layout = props => {
  const { children, seo, location } = props;
  setupHubspotChat();

  const cateringPages = [
    '/office-catering',
    '/breaky',
    '/morning-tea',
    '/staff-lunch',
    '/afternoon-tea',
    '/finger-food',
    '/seminars',
    '/buffets',
    '/office-pizza',
    '/office-cakes-delivery',
    '/burgers',
    '/healthy-catering',
    '/vietnamese-catering',
    '/mexican-catering',
    '/sushi-catering',
  ];

  const snacksPages = [
    '/kitchen-and-pantry',
    '/office-snacks',
    '/cleaning-supplies',
    '/kitchenware',
    '/pantry-management',
  ];

  const pantryPages = ['/office-bread', '/office-milk', '/office-coffee'];

  const beveragePages = ['/office-drinks', '/office-alcohol'];

  const fruitPages = ['/office-fruit'];

  const blogRoutes = ['/blog'];

  const isHomepage = location && location.pathname === '/';
  const isBlog = location && blogRoutes.find(slug => location.pathname.indexOf(slug) !== -1);
  const isCateringPage = location && cateringPages.find(slug => location.pathname.indexOf(slug) !== -1);
  const isSnacksPage = location && snacksPages.find(slug => location.pathname.indexOf(slug) !== -1);
  const isPantryPage = location && pantryPages.find(slug => location.pathname.indexOf(slug) !== -1);
  const isBeveragesPage = location && beveragePages.find(slug => location.pathname.indexOf(slug) !== -1);
  const isFruitPage = location && fruitPages.find(slug => location.pathname.indexOf(slug) !== -1);

  const getPageClass = () => {
    if (isHomepage) return 'homepage';
    if (blogRoutes) return 'blog';
    if (isCateringPage) return 'catering-page';
    if (isSnacksPage) return 'snacks-page';
    if (isBeveragesPage) return 'beverage-page';
    if (isFruitPage) return 'fruit-page';
    if (isPantryPage) return 'pantry-page';
    return 'page';
  };

  return (
    <UserProvider>
      <div className={getPageClass()}>
        <LogRocket />
        <Header location={location} />
        <main className="main-content">
          <SEO {...seo} />
          {children}
        </main>
        <Footer />
      </div>
      <Helmet>
        <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/21281170.js" />
      </Helmet>
    </UserProvider>
  );
};
