import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import './Image.scss';

export const Image = props => {
  const { image, className = '', style = {} } = props;
  if (!image) return <div className={`gatsby-image placeholder ${className}`} />;
  if (image.localFile && image.localFile.childImageSharp && image.localFile.childImageSharp.fluid) {
    return (
      <Img
        className={`gatsby-image ${className}`}
        fluid={image.localFile.childImageSharp.fluid}
        alt={image.alt || 'Yordar Office Catering'}
        style={style}
      />
    );
  }
  if (image.url) {
    return (
      <div className={`gatsby-image ${className}`}>
        <img src={image.url} alt={image.alt || 'Yordar Office Catering'} />
      </div>
    );
  }
  return <div className={`gatsby-image placeholder ${className}`} />;
};
