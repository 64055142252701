import React, { useState, useEffect, createContext } from 'react';
import axios from 'axios';

const defaultUserContext = {
  user: null,
  cart: null,
};

const UserContext = createContext(defaultUserContext);

export const UserProvider = props => {
  const { children } = props;
  const [user, setUser] = useState(false);
  const [cart, setCart] = useState(false);

  useEffect(() => {
    const LOGIN_ENPOINT = `${process.env.YORDAR_APP_URL}/api/gatsby/status/login.json`;
    const CART_ENPOINT = `${process.env.YORDAR_APP_URL}/api/gatsby/status/cart.json`;
    const getAuthData = () => axios.get(LOGIN_ENPOINT, { withCredentials: true });
    const getCartData = () => axios.get(CART_ENPOINT, { withCredentials: true });

    const fetchAuthData = async () => {
      const [authRes, cartRes] = await axios.all([getAuthData(), getCartData()]);
      setUser(authRes && authRes.data ? authRes.data.user : null);
      setCart(cartRes && cartRes.data ? cartRes.data.cart : null);
    };

    fetchAuthData();
  }, [children]);

  return (
    <UserContext.Provider
      value={{
        ...defaultUserContext,
        user,
        cart,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
export const UserConsumer = UserContext.Consumer;

export default UserContext;
