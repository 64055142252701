import React, { useState, useEffect } from 'react';
import OnVisible from 'react-on-visible';
import { CountUp } from 'countup.js';
import './styles.scss';

const Stats = ({ data }) => {
  const { items } = data;

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (visible) {
      items.forEach(item => {
        const target = document.getElementById(item.name.text.replace(/\s+/g, '-').toLowerCase());
        new CountUp(target, parseInt(item.stat.text, 0)).start();
      });
    }
  }, [visible]);

  return (
    <OnVisible onChange={isVisible => setVisible(isVisible)} className="stats-section">
      <div className="wrapper">
        {items.map(stat => (
          <Stat key={stat.name.text} {...stat} />
        ))}
      </div>
    </OnVisible>
  );
};

const Stat = ({ icon, stat, name }) => (
  <div className="stats-section-item">
    <img className="stats-section-item-icon" src={icon.url} alt={`${stat.text} ${name.text}`} />
    <span className="stats-section-item-content">
      <strong>
        <span id={name.text.replace(/\s+/g, '-').toLowerCase()}>{stat.text}</span>
        {name.text === 'Satisfaction rating' ? '%' : null}
      </strong>
      {name.text}
    </span>
  </div>
);

export default Stats;
