import React from 'react';
import './styles.scss';

const TextBlock = ({ data }) => {
  const { primary } = data;
  const { content } = primary;
  const contentHtml = content && content.html;

  if (!contentHtml || contentHtml === '') return null;

  return (
    <section className="text-block">
      <div className="wrapper" dangerouslySetInnerHTML={{ __html: contentHtml }} />
    </section>
  );
};

export default TextBlock;
