module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'Office Catering Sydney, Melbourne and Brisbane | Corporate Catering', // Default Site Title used for SEO & PWA
  description: 'Australia\'s best corporate caterers and suppliers ready to deliver office catering and kitchen supplies to your office.', // Default Site Decription used for SEO
  siteName: 'Yordar', // Sitename for Facebook
  siteLanguage: 'en', // Language Tag on <html> element
  banner: '/logos/logo-1024.jpg', // Default OpenGraph image
  ogLanguage: 'en_US', // Facebook Language
  appBaseUrl: 'https://www.yordar.com.au',

  // JSONLD / Manifest
  favicon: 'src/favicon.png', // Used for manifest favicon generation
  shortName: 'Yordar', // shortname for manifest. MUST be shorter than 12 characters
  author: 'Yordar', // Author for schemaORGJSONLD
  themeColor: '#333333',
  backgroundColor: '#C71D27',

  twitter: '', // Twitter Username
  googleTagManagerId: 'GTM-57BH9Z5',
};
