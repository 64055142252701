import React from 'react';
import { Button, Image } from '../../components';
import './styles.scss';

const Steps = ({ data }) => {
  const { primary, items } = data;
  const { title, image, button_link: buttonLink, button_text: buttonText } = primary;
  const hasButton = buttonLink && buttonLink.url && buttonText && buttonText.text;
  return (
    <section className="steps-section">
      <div className="wrapper">
        <div className="steps-image">
          <Image image={image} />
          <h2 className="steps-image-title">{title.text}</h2>
        </div>
        <div className="steps-container">
          {items.map(step => (
            <div className="step">
              <h3>{step.title.text}</h3>
              <div dangerouslySetInnerHTML={{ __html: step.content.html }} />
            </div>
          ))}
          {hasButton && <Button to={buttonLink.url}>{buttonText.text}</Button>}
        </div>
      </div>
    </section>
  );
};

export default Steps;
