import React from 'react';
import { Image, Link } from 'components';
import './BlogCard.scss';

const truncateString = (string, maxLength = 50) => {
  if (!string) return null;
  const showDots = string.length > maxLength;
  return `${string.substring(0, maxLength)}${showDots ? '...' : ''}`;
};

export const BlogCard = props => {
  const { uid, title, featured, date, excerpt } = props;
  return (
    <Link to={`/blog/${uid}`} className="blog-card">
      <Image image={featured} />
      <div className="body-card-body">
        <h4 className="blog-card-title" dangerouslySetInnerHTML={{ __html: title.text }} />
        {date && <span className="blog-card-date">{new Date(date).toLocaleDateString('en-AU')}</span>}
        <div className="blog-card-excerpt" dangerouslySetInnerHTML={{ __html: truncateString(excerpt.text, 80) }} />
      </div>
      <span className="button blog-card-button primary">Read more...</span>
    </Link>
  );
};
