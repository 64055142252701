import React, { useState, useRef } from 'react';
import { navigate } from 'gatsby';
import axios from 'axios';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import { Formik } from 'formik';
import { FormHeader, Fields } from 'components/Form';

// Form fields
const fields = [
  {
    type: 'formTitle',
    title: 'Company Details',
  },
  {
    type: 'input',
    inputType: 'text',
    name: 'firstName',
    label: 'First Name *',
    split: true,
  },
  {
    type: 'input',
    inputType: 'text',
    name: 'lastName',
    label: 'Last Name *',
    split: true,
  },
  {
    type: 'input',
    inputType: 'text',
    name: 'company',
    label: 'Company Name',
    split: true,
  },
  {
    type: 'select',
    name: 'numberOfStaff',
    label: 'Number of staff',
    placeholder: 'Select an option',
    split: true,
    options: [
      { value: '26-50', label: '26-50' },
      { value: '51-100', label: '51-100' },
      { value: '101-200+', label: '101-200+' },
    ],
  },
  {
    type: 'input',
    inputType: 'tel',
    name: 'phone',
    label: 'Phone Number *',
    split: true,
  },
  {
    type: 'input',
    inputType: 'email',
    name: 'emailAddress',
    label: 'Email *',
    split: true,
  },
  {
    type: 'formTitle',
    title: 'Company Locations',
  },
  {
    type: 'checkbox',
    name: 'locations',
    options: [
      { value: 'NSW', label: 'NSW' },
      { value: 'QLD', label: 'QLD' },
      { value: 'VIC', label: 'VIC' },
      { value: 'WA', label: 'WA' },
      { value: 'SA', label: 'SA' },
      { value: 'ACT', label: 'ACT' },
    ],
  },
  {
    type: 'formTitle',
    title: 'Services of interest',
  },
  {
    type: 'checkbox',
    name: 'services',
    options: [
      { value: 'Catering', label: 'Catering' },
      { value: 'Fruit Boxes', label: 'Fruit Boxes' },
      { value: 'Milk', label: 'Milk' },
      { value: 'Bread', label: 'Bread' },
      { value: 'Pantry Supplies', label: 'Pantry Supplies' },
      { value: 'Alcohol', label: 'Alcohol' },
      { value: 'Beverages', label: 'Beverages' },
      { value: 'Cleaning', label: 'Cleaning' },
      { value: 'Coffee', label: 'Coffee' },
      { value: 'Corporate Gift', label: 'Corporate Gift' },
    ],
  },
];

// Form inital values
const initialValues = {
  firstName: '',
  lastName: '',
  company: '',
  numberOfStaff: '',
  phone: '',
  emailAddress: '',
  locations: [],
  services: [],
};

// Handle form validation
const validate = values => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = 'Please provide your first name';
  } else if (!values.lastName) {
    errors.lastName = 'Please provide your last name';
  } else if (!values.phone) {
    errors.phone = 'Please provide a phone number';
  } else if (!values.emailAddress) {
    errors.emailAddress = 'Please provide an email address';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.emailAddress)) {
    errors.emailAddress = 'Please provide a valid email address';
  }
  return errors;
};

// Encode values to query string
const encode = data =>
  Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');

const BulkDiscount = () => {
  const formSection = useRef();
  const [success, setSuccess] = useState(false);

  // Handle form submit
  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      // Hide previous success message
      setSuccess(false);
      try {
        // Submit form to email service
        await axios.post(
          'https://a5w3o6c2j1.execute-api.ap-southeast-2.amazonaws.com/dev?format=json',
          encode({
            ...values,
            _to: '938d36a79cb031187fc31dd264fc9e2233163f09',
            _sender: 'Yordar',
            _formname: 'Bulk Discount',
            _replyTo: values.emailAddress,
          }),
          {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          }
        );
        // Add user to active campaign
        await axios.get(
          `/api/active-campaign?${encode({
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.emailAddress,
            phone: values.phone,
            list: 3,
            tag: 7,
          })}`
        );
        // Send to success page
        navigate('/thank-you');
      } catch (error) {
        // Usually fails if user is already in system
        console.error('Error adding to Active Campaign', error);
      }
      // Reset form values
      resetForm(initialValues);
      // Show success message
      setSuccess(true);
      scrollIntoView(formSection.current, { behavior: 'smooth', block: 'start' });
    } catch (error) {
      console.error('There was a problem submitting your form! Try again or reload the page :)', error);
    }
    // Set submitting as false
    setSubmitting(false);
  };

  return (
    <div className="form-section bulk-discount-form" ref={formSection}>
      <FormHeader
        title="Volume Discount Request"
        description="Congratulations! You are only around 2 minutes away from saving your company some money on one or more of our services, please fill out the below form and one of our enterprise client service managers will contact you shortly."
      />
      {success && <p className="success">Thank you, one of our team will be in touch soon</p>}
      <Formik initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <form className="form" name="bulk-discount" method="POST" onSubmit={handleSubmit}>
            <Fields
              fields={fields}
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <button type="submit" className="button" disabled={isSubmitting}>
              {isSubmitting ? 'Sending Request' : 'Send Request'}
            </button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default BulkDiscount;
