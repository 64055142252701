import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

export const WithGoogleApi = WrappedComponent => {
  const WithGoogleApiComponent = props => {
    const { site } = useStaticQuery(query);
    const {
      siteMetadata: { googleApiKey },
    } = site;

    const [mounted, setMounted] = useState(false);

    const onLoad = () => {
      setMounted(true);
    };

    useEffect(() => {
      if (!window.google) {
        const script = document.createElement(`script`);
        script.type = `text/javascript`;
        script.src = `https://maps.google.com/maps/api/js?key=${googleApiKey}&libraries=places`;
        const headScript = document.getElementsByTagName(`script`)[0];
        headScript.parentNode.insertBefore(script, headScript);
        script.addEventListener(`load`, onLoad);
        return () => script.removeEventListener(`load`, onLoad);
      }
      onLoad();
    });

    if (!mounted) return null;
    return <WrappedComponent {...props} />;
  };
  return WithGoogleApiComponent;
};

const query = graphql`
  query googleApiQuery {
    site {
      siteMetadata {
        googleApiKey
      }
    }
  }
`;
