import React from 'react';
import './FormHeader.scss';

const FormHeader = ({ title, description }) => (
  <div className="form-header">
    {title && <span className="form-header-title">{title}</span>}
    {description && <p className="form-header-description">{description}</p>}
  </div>
);

export default FormHeader;
