import React from 'react';
import LogRocketModule from 'logrocket';
import { UserConsumer } from '../context/user';

const initializeLogrocket = user => {
  LogRocketModule.init('ojq7n8/yordar', {
    rootHostname: 'yordar.com.au',
  });
  if (user) {
    LogRocketModule.identify(user.id, {
      name: `${user.first_name} ${user.last_name}`,
      email: user.email,
    });
  }
};

const LogRocket = () => {
  if (process.env.NODE_ENV === 'development') return null;

  return (
    <UserConsumer>
      {({ user }) => {
        initializeLogrocket(user);
      }}
    </UserConsumer>
  );
};

export default LogRocket;
