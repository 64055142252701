import React, { useState, useEffect } from 'react';
import { Link } from './Link';
import { header as headerNav } from '../../config/navigations';
import { Hamburger } from './Hamburger';
import './HeaderNavigation.scss';

const HeaderNavigationLink = ({ className, to, text }) => (
  <li>
    <Link className={className || ''} to={to}>
      {text}
    </Link>
  </li>
);

export const HeaderNavigation = () => {
  const [showNav, setShowNav] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [bodyLocked, setBodyLock] = useState(false);

  const toggleNav = event => {
    event.preventDefault();
    setShowNav(!showNav);
  };

  const handleEscKey = event => {
    if (event.keyCode === 27) {
      document.body.classList.remove('nav-open');
      setShowNav(false);
    }
  };

  const lockBody = () => {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    setScrollTop(scrollPosition);
    document.body.style.top = `-${scrollPosition}px`;
    document.body.classList.add('nav-open');
    setBodyLock(true);
  };

  const unlockBody = () => {
    document.body.classList.remove('nav-open');
    document.body.style.top = '0px';
    window.scrollTo(0, scrollTop);
    setScrollTop(0);
    setBodyLock(false);
  };

  useEffect(() => {
    document.addEventListener('keydown', handleEscKey, false);
    return () => {
      document.removeEventListener('keydown', handleEscKey, false);
      unlockBody();
    };
  }, []);

  useEffect(() => {
    if (showNav && !bodyLocked) lockBody();
    if (!showNav && bodyLocked) unlockBody();
  }, [showNav]);

  return (
    <nav className="header-navigation" role="navigation">
      <ul className={`header-navigation-parent-nav ${showNav ? 'open' : 'closed'}`}>
        <li className="header-navigation-parent-link">
          <span className="header-navigation-parent-link-toggle">Our Services</span>
          <div className="header-navigation-dropdown">
            <span className="header-navigation-dropdown-title">Catering Services</span>
            <ul className="header-navigation-dropdown-links">
              {headerNav.catering.popularCuisines.map(link => (
                <HeaderNavigationLink key={link.to + link.className} {...link} />
              ))}
            </ul>
            <span className="header-navigation-dropdown-title">Snacks & Pantry Services</span>
            <ul className="header-navigation-dropdown-links">
              {headerNav.pantry.map(link => (
                <HeaderNavigationLink key={link.to + link.className} {...link} />
              ))}
            </ul>
          </div>
        </li>
        <li className="header-navigation-parent-link large-tablet-down">
          <span className="header-navigation-parent-link-toggle">Your Account</span>
          <div className="header-navigation-dropdown">
            <ul className="header-navigation-dropdown-links">
              {headerNav.account.map(link => (
                <HeaderNavigationLink key={link.to + link.className} {...link} />
              ))}
            </ul>
          </div>
        </li>
      </ul>
      <Hamburger active={showNav} onClick={toggleNav} />
    </nav>
  );
};
