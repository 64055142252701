import React from 'react';
//

export const AdminBanner = ({ name }) => (
  <p className="admin-banner">
    You're logged in as {name}.{' '}
    <a href={`${process.env.YORDAR_APP_URL}/sign_out_as`} className="admin-logout">
      Click here when you're finished
    </a>
  </p>
);
