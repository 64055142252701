import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Carousel, Image, WaveBorder } from '../../components';
import './styles.scss';

const logosQuery = graphql`
  query {
    ...ClientLogosFragment
    ...SupplierLogosFragment
  }
`;

const Logos = props => {
  const { data } = props;
  const { primary } = data;
  const { title, logos } = primary;
  const carouselResponsive = {
    0: { items: 2 },
    500: { items: 3 },
    600: { items: 4 },
  };
  return (
    <StaticQuery
      query={logosQuery}
      render={queryData => {
        const { clientLogos = [], supplierLogos = [] } = queryData;
        const logoArr = logos === 'Client logos' ? clientLogos.data.logos : supplierLogos.data.supplier_logos;
        return (
          <section className="logos">
            <div className="wrapper">
              {title && title.text && <span className="logos-title">{title.text}</span>}
              <Carousel
                containerClassName="logos-body"
                responsive={carouselResponsive}
                disableSlider={logoArr.length > 6}
                enabledBetween={[0, 1024]}
                buttonsDisabled
                infinite
              >
                {logoArr.map(item => (
                  <div className="logos-body-logo" key={item.logo.alt || item.logo.url}>
                    <Image image={item.logo} />
                  </div>
                ))}
              </Carousel>
            </div>
            {/* <WaveBorder fill="#C71D27" /> */}
          </section>
        );
      }}
    />
  );
};

export default Logos;
