import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Image } from '../Image';
import './SupplierGrid.scss';

const imageQueries = graphql`
  query {
    supplierImage: file(relativePath: { eq: "images/corporate-catering-suppliers.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export const SupplierGrid = () => (
  <StaticQuery
    query={imageQueries}
    render={({ supplierImage }) => (
      <section className="supplier-grid">
        <div className="wrapper">
          <div className="col">
            <div className="row">
              <h2>Come on board and you'll see very quickly the benefits of working with Yordar.</h2>
              <div className="grid-item exposure">
                <h3>Increase Exposure</h3>
                <p>Open your business up to our large customer base and drive more sales</p>
              </div>
            </div>
            <div className="row">
              <div className="grid-item help">
                <h3>Here to help</h3>
                <p>
                  Yordar wants your business to thrive. We can suggest ways to improve sales to your business, make
                  suggestions on menu and catalogue price competitiveness.
                </p>
              </div>
              <Image image={{ localFile: supplierImage }} alt="Become a Supplier" />
            </div>
            <div className="row">
              <p>There is everything to gain and nothing to lose by becoming a supplier with Yordar.</p>
              <a href="https://app.yordar.com.au/supplier-registration" className="button large">
                Become a supplier
              </a>
            </div>
          </div>
          <div className="col">
            <div className="grid-item billing">
              <h3>Simplified Billing</h3>
              <p>No more chasing multiple accounts, Yordar manages all payments directly into your account.</p>
            </div>
            <div className="grid-item national">
              <h3>Go National</h3>
              <p>Leverage our national supplier network and get the clients you couldn’t before</p>
            </div>
            <div className="grid-item storefront">
              <h3>Account Management</h3>
              <p>Our friendly team are always available to help support your business journey with Yordar.</p>
            </div>
          </div>
        </div>
      </section>
    )}
  />
);
