import React from 'react';
import { FieldArray } from 'formik';
import './Fields.scss';

const Fields = ({ fields, values, errors, handleChange, handleBlur }) => (
  <div className="form-fields">
    {fields.map(field => {
      const { type, name, label, placeholder, inputType, options, ...otherFieldProps } = field;

      // Form title
      if (type === 'formTitle' && otherFieldProps.title) {
        return (
          <div key={otherFieldProps.title} className="form-fields-title">
            {otherFieldProps.title}
          </div>
        );
      }

      // Input field
      if (type === 'input') {
        return (
          <div key={name} className={`form-field ${otherFieldProps.split ? 'split' : ''}`}>
            <label htmlFor={name}>
              <span className="label">{label}</span>
              <input
                type={inputType || 'text'}
                name={name}
                id={name}
                placeholder={placeholder}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values[name] || ''}
              />
            </label>
            <span className={`validation-error ${errors[name] ? 'active' : ''}`}>{errors[name]}</span>
          </div>
        );
      }

      // Textarea field
      if (type === 'textarea') {
        return (
          <div key={name} className={`form-field ${otherFieldProps.split ? 'split' : ''}`}>
            <label htmlFor={name}>
              <span className="label">{label}</span>
              <textarea
                type={inputType || 'text'}
                name={name}
                id={name}
                placeholder={placeholder}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values[name] || ''}
              />
            </label>
            <span className={`validation-error ${errors[name] ? 'active' : ''}`}>{errors[name]}</span>
          </div>
        );
      }

      // Select field
      if (type === 'select' && options) {
        return (
          <div key={name} className={`form-field ${otherFieldProps.split ? 'split' : ''}`}>
            <label htmlFor={name}>
              <span className="label">{label}</span>
              <select name={name} id={name} onChange={handleChange} onBlur={handleBlur} value={values[name]}>
                {placeholder && <option value={placeholder}>{placeholder}</option>}
                {options.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </label>
          </div>
        );
      }

      // Checkbox field (single and multi)
      if (type === 'checkbox' && options) {
        if (!options) return null;

        // Handle error when missing initial value
        if (!values[name]) return console.error('Using checkbox without initial empty array');

        // Handle single checkbox
        if (options.length === 1) {
          const option = options[0];
          return (
            <div key={name} className="form-field checkbox">
              {label && <span className="label">{label}</span>}
              <div className="option">
                <input
                  type="checkbox"
                  name={name}
                  value={option.value}
                  id={option.value}
                  checked={values[name] === option.value}
                  onChange={handleChange}
                />
                {/* eslint-disable-next-line */}
                <label htmlFor={option.value}>{option.label}</label>
              </div>
              <span className={`validation-error ${errors[name] ? 'active' : ''}`}>{errors[name]}</span>
            </div>
          );
        }

        // Handle checkbox group
        return (
          <div key={name} className="form-field checkbox">
            {label && <span className="label">{label}</span>}
            <FieldArray
              name={name}
              render={arrayHelpers => (
                <div className="checkbox-options">
                  {options.map(option => {
                    const handleCheckboxChange = event => {
                      if (event.target.checked) arrayHelpers.push(option.value);
                      else {
                        const index = values[name].indexOf(option.value);
                        arrayHelpers.remove(index);
                      }
                    };

                    return (
                      <div key={option.value} className="option">
                        <input
                          type="checkbox"
                          name={name}
                          value={option.value}
                          id={option.value}
                          checked={values[name].includes(option.value)}
                          onChange={handleCheckboxChange}
                        />
                        {/* eslint-disable-next-line */}
                        <label htmlFor={option.value}>{option.label}</label>
                      </div>
                    );
                  })}
                </div>
              )}
            />
            <span className={`validation-error ${errors[name] ? 'active' : ''}`}>{errors[name]}</span>
          </div>
        );
      }

      return null;
    })}
  </div>
);

export default Fields;
