import React from 'react';
import { Button, Image, Tagline } from 'components';
import './styles.scss';

const getTag = type => {
  switch (type) {
    case 'heading1':
      return 'h1';
    case 'heading2':
      return 'h2';
    case 'heading3':
      return 'h3';
    case 'heading4':
      return 'h4';
    case 'heading5':
      return 'h5';
    default:
      return 'span';
  }
};

const ImageTextColumns = ({ data }) => {
  const { items } = data;
  return (
    <section className="image-text-columns">
      {items.map(item => (
        <Column key={item.title.text} {...item} />
      ))}
    </section>
  );
};

const showHubSpotChat = event => {
  event.preventDefault();
  const { HubSpotConversations } = window;
  if (HubSpotConversations) HubSpotConversations.widget.open();
};

const Column = ({
  image,
  button_link: buttonLink,
  button_text: buttonText,
  tagline,
  title,
  content,
  custom_class: customClass,
}) => {
  const taglineText = tagline && tagline.text;
  const titleRaw = title && title.raw && title.raw[0];
  const titleType = titleRaw ? titleRaw.type : null;
  const titleText = titleRaw ? titleRaw.text : null;
  const TitleTag = getTag(titleType);
  const hasButton =
    buttonLink && buttonLink.url && buttonText && buttonText.text;

  return (
    <div className={`image-text-row ${customClass ? customClass.text : ""}`}>
      <div className="wrapper">
        <div className="image-text-col image-col">
          <Image image={image} />
        </div>
        <div className="image-text-col text-col">
          <Tagline tagline={taglineText} />
          {titleText && <TitleTag className="image-text-col-title">{titleText}</TitleTag>}
          {content && content.html && (
            <div className="image-text-col-content" dangerouslySetInnerHTML={{ __html: content.html }} />
          )}
          {hasButton && buttonLink.url.indexOf('intercom') === -1 && (
            <Button to={buttonLink.url}>{buttonText.text}</Button>
          )}
          {hasButton && buttonLink.url.indexOf('intercom') !== -1 && (
            <a href="#hs-chat-open" className="button" onClick={showHubSpotChat}>
              {buttonText.text}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageTextColumns;
