import React from 'react';
import { SupplierGrid } from '../../components/CustomUi';

const CustomUi = props => {
  const { data } = props;
  const { primary } = data;
  const { ui } = primary;
  if (ui === 'Supplier grid with centred image') {
    return <SupplierGrid />;
  }
  return null;
};

export default CustomUi;
