import React from 'react';
import OnVisible from 'react-on-visible';
import SearchBar from './SearchBar';
import './HeroSearch.scss';

export const HeroSearch = props => {
  const { title, subtitle, illustration } = props;
  return (
    <OnVisible className="hero-search">
      <div className="wrapper">
        <div className="hero-search-left">
          <h1 className="hero-search-title" dangerouslySetInnerHTML={{ __html: title }}></h1>
          <h3 className="hero-search-subtitle">{subtitle}</h3>
          <SearchBar buttonTheme="primary" />
        </div>
        {illustration && illustration.url && (
          <div className="hero-search-illustration">
            <img src={illustration.url} alt={title} />
          </div>
        )}
      </div>
    </OnVisible>
  );
};
