import React from 'react';
import { Link } from 'gatsby';
import { siteName } from '../../config/website';
import './Logo.scss';

export const Logo = ({ theme }) => (
  <Link className={`logo ${theme || ''}`} to="/" title={siteName}>
    {siteName}
  </Link>
);
