import React, { useContext } from 'react';
import UserContext from '../context/user';
import { Logo } from './Logo';
import { HeaderNavigation } from './HeaderNavigation';
import { HeaderActions } from './HeaderActions';
import { AdminBanner } from './AdminBanner';
import './Header.scss';

export const Header = ({ location, cartData }) => {
  const { user } = useContext(UserContext);
  const showBanner = user?.signed_in_as_admin && user?.profile;
  const name = `${user?.first_name} ${user?.last_name}`;
  return (
    <header className="header">
      {showBanner && <AdminBanner name={name} />}
      <div className="wrapper">
        <Logo theme="dark" />
        <HeaderNavigation />
        <HeaderActions location={location} cartData={cartData} />
      </div>
    </header>
  );
};
