module.exports = {
  header: {
    catering: {
      popularCuisines: [
        {
          className: 'icon icon-restaurant',
          to: `/office-catering`,
          text: 'Office Catering',
        },
        {
          className: 'icon icon-staff-lunch',
          to: `/staff-meal-program`,
          text: 'Staff Meal Program',
        },
        {
          className: 'icon icon-team',
          to: '/team-ordering',
          text: 'Team Ordering',
        },
        {
          className: 'icon icon-event',
          to: `/events`,
          text: 'Event Catering',
        },
      ],
    },
    pantry: [
      {
        className: 'icon icon-chocolate-bar',
        to: '/office-snacks-pantry',
        text: 'Office Snacks & Pantry',
      },
      {
        className: 'icon icon-shopping-basket',
        to: '/pantry-management',
        text: 'Pantry Management',
      },
    ],
    account: [
      {
        className: 'icon icon-user',
        to: `${process.env.YORDAR_APP_URL}/login`,
        text: 'Login/Register',
      },
      {
        className: 'icon icon-shopping-basket',
        to: `${process.env.YORDAR_APP_URL}/order/summary`,
        text: 'Cart',
      },
    ],
  },
  footer: {
    catering: [
      {
        className: 'icon icon-home',
        to: '/yordar-from-home',
        text: 'Home Delivery',
      },
      {
        className: 'icon icon-toaster',
        to: '/breaky',
        text: 'Breaky',
      },
      {
        className: 'icon icon-teapot',
        to: '/morning-tea',
        text: 'Morning Tea',
      },
      {
        className: 'icon icon-restaurant',
        to: '/staff-lunch',
        text: 'Staff Lunch',
      },
      {
        className: 'icon icon-teacup',
        to: '/afternoon-tea',
        text: 'Afternoon Tea',
      },
      {
        className: 'icon icon-hand-right',
        to: '/finger-food',
        text: 'Finger Food',
      },
      {
        className: 'icon icon-restaurant',
        to: '/grazing-table-catering',
        text: 'Grazing Tables',
      },
      {
        className: 'icon icon-plus',
        to: '/office-catering',
        text: 'View all',
      },
    ],
    pantry: [
      {
        className: 'icon icon-bread',
        to: '/office-bread',
        text: 'Office Bread',
      },
      {
        className: 'icon icon-solo-cup',
        to: '/office-drinks',
        text: 'Office Drinks',
      },
      {
        className: 'icon icon-coffee',
        to: '/office-coffee-supplies',
        text: 'Office Coffee Supplies',
      },
      {
        className: 'icon icon-apple',
        to: '/office-fruit',
        text: 'Office Fruit',
      },
      {
        className: 'icon icon-wine-glass',
        to: '/office-alcohol',
        text: 'Office Alcohol',
      },
      {
        className: 'icon icon-milk',
        to: '/office-milk',
        text: 'Office Milk',
      },
      {
        className: 'icon icon-chocolate-bar',
        to: '/office-snacks-pantry',
        text: 'Office Snacks',
      },
      {
        className: 'icon icon-shopping-basket',
        to: '/pantry-management',
        text: 'Pantry Management',
      },
      {
        className: 'icon icon-plus',
        to: '/kitchen-and-pantry',
        text: 'View all',
      },
    ],
    somethingElse: [
      {
        className: 'icon icon-team',
        to: '/team-ordering',
        text: 'Team Ordering',
      },
      {
        className: 'icon icon-event',
        to: '/events',
        text: 'Event Catering',
      },
      {
        className: 'icon icon-event',
        to: '/bulk-discount',
        text: '50+ Staff',
      },
    ],
    help: [
      {
        className: 'icon icon-about-us',
        to: '/about-us',
        text: 'About Us',
      },
      {
        className: 'icon icon-why-yordar',
        to: '/why-yordar',
        text: 'Why Yordar',
      },
      {
        className: 'icon icon-contact-us',
        to: '/contact-us',
        text: 'Contact Us',
      },
      {
        className: 'icon icon-truck',
        to: '/supplier-partnership',
        text: 'For Suppliers',
      },
      {
        className: 'icon icon-hand-right',
        to: '/blog',
        text: 'Blog',
      },
    ],
    locations: [
      {
        className: 'icon icon-marker',
        to: '/office-catering/sydney',
        text: 'Sydney',
      },
      {
        className: 'icon icon-marker',
        to: '/office-catering/melbourne',
        text: 'Melbourne',
      },
      {
        className: 'icon icon-marker',
        to: '/office-catering/brisbane',
        text: 'Brisbane',
      },
      {
        className: 'icon icon-marker',
        to: '/office-catering/perth',
        text: 'Perth',
      },
      {
        className: 'icon icon-marker',
        to: '/office-catering/adelaide',
        text: 'Adelaide',
      },
      {
        className: 'icon icon-marker',
        to: '/office-catering/canberra',
        text: 'Canberra',
      },
    ],
  },
};
