import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Image, Link, SectionHeader } from 'components';
import './OtherServices.scss';

const serviceImagesQuery = graphql`
  query {
    serviceImages: prismicSettings {
      data {
        catering_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 600, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        office_snacks_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 600, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        office_milk_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 600, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        office_fruit_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 600, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

export const OtherServices = props => {
  const {
    title,
    tagline,
    suburb,
    suburbSlug,
    category,
    hasCatering,
    hasOfficeSnacks,
    hasOfficeMilk,
    hasOfficeFruit,
  } = props;

  const services = ['Office Catering', 'Office Snacks & Pantry', 'Office Milk', 'Office Fruit'];
  const indexOfCurrentService = services.indexOf(category);
  services.splice(indexOfCurrentService, 1);

  return (
    <StaticQuery
      query={serviceImagesQuery}
      render={queryData => {
        const { serviceImages } = queryData;
        const {
          catering_image: cateringImage,
          office_snacks_image: officeSnacksImage,
          office_milk_image: officeMilkImage,
          office_fruit_image: officeFruitImage,
        } = serviceImages.data;

        const imageMap = {
          'Office Catering': cateringImage,
          'Office Snacks & Pantry': officeSnacksImage,
          'Office Milk': officeMilkImage,
          'Office Fruit': officeFruitImage,
        };

        const getLink = service => {
          if (service === 'Office Catering' && hasCatering === 'Yes') return `/office-catering/${suburbSlug}`;
          if (service === 'Office Catering') return '/office-catering';
          if (service === 'Office Snacks & Pantry' && hasOfficeSnacks === 'Yes') return `/office-snacks-pantry/${suburbSlug}`;
          if (service === 'Office Snacks & Pantry') return '/office-snacks-pantry';
          if (service === 'Office Milk' && hasOfficeMilk === 'Yes') return `/office-milk/${suburbSlug}`;
          if (service === 'Office Milk') return '/office-milk';
          if (service === 'Office Fruit' && hasOfficeFruit === 'Yes') return `/office-fruit/${suburbSlug}`;
          if (service === 'Office Fruit') return '/office-fruit';
        };

        return (
          <section className="other-services">
            <div className="wrapper">
              <SectionHeader title={title} TitleTag="span" tagline={tagline} TaglineTag="h3" />
              <div className="other-services-grid">
                {services.map(service => (
                  <Link key={service} to={getLink(service)} className="other-services-item">
                    <Image image={imageMap[service]} />
                    <h4 className="other-services-item-title">{service}</h4>
                  </Link>
                ))}
              </div>
            </div>
          </section>
        );
      }}
    />
  );
};
