import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import facebookIcon from '../images/social/facebook.svg';
import instagramIcon from '../images/social/instagram.svg';
import twitterIcon from '../images/social/twitter.svg';
import linkedInIcon from '../images/social/linkedin.svg';
import googleIcon from '../images/social/google.svg';
import pinterestIcon from '../images/social/pinterest.svg';
import emailIcon from '../images/social/email.svg';
import './SocialLinks.scss';

const socialLinksQuery = graphql`
  query {
    settings: prismicSettings {
      data {
        social_links {
          platform
          url {
            url
          }
        }
      }
    }
  }
`;

const socialIcons = {
  Facebook: facebookIcon,
  Instagram: instagramIcon,
  Twitter: twitterIcon,
  LinkedIn: linkedInIcon,
  Google: googleIcon,
  Pinterest: pinterestIcon,
  Email: emailIcon,
};

export const SocialLinks = () => (
  <StaticQuery
    query={socialLinksQuery}
    render={data => {
      const { social_links: socialLinks } = data.settings.data;
      if (!socialLinks || socialLinks.length < 1) return null;
      return (
        <div className="social-links">
          <h5>Follow Us</h5>
          <nav className="social-links-nav">
            {socialLinks.map(socialLink => (
              <a
                key={socialLink.url.url}
                href={socialLink.url.url}
                target="_blank"
                rel="noreferrer noopener"
                aria-label={socialLink.platform}
              >
                <img src={socialIcons[socialLink.platform]} alt="Instagram" />
              </a>
            ))}
          </nav>
        </div>
      );
    }}
  />
);
