import React, { useState } from 'react';
import axios from 'axios';
import './Newsletter.scss';

const ErrorMessage = ({ submitError }) => {
  if (!submitError) return null;
  const alreadySubscribed = submitError.indexOf('already subscribed') || submitError.indexOf('too many requests');
  const message = alreadySubscribed
    ? "Looks like you're already subscribed to our mailing list."
    : 'Uh oh! Something went wrong!';
  return <span className="newsletter-error">{message}</span>;
};

const SuccessMessage = ({ submitSuccess }) => {
  if (!submitSuccess) return null;
  return (
    <div className="newsletter-success">
      <span>Thanks for joining our mailing list</span>
    </div>
  );
};

export const Newsletter = () => {
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [email, setEmail] = useState(null);

  const handleSubmit = async event => {
    event.preventDefault();
    setSubmitting(true);
    setSubmitError(false);
    setSubmitSuccess(false);

    const result = await axios.get(`/api/active-campaign?email=${email}&&list=3&tag=15`);
    if (result.body.email) {
      setSubmitting(false);
      setSubmitSuccess(true);
      setEmail(null);
    } else {
      setSubmitting(false);
      setSubmitError(result.msg);
    }
  };

  const handleChange = event => {
    setEmail(event.target.value);
  };

  return (
    <section className="newsletter">
      <h5 className="newsletter-title">Join our Newsletter</h5>
      <div className="newsletter-form">
        <ErrorMessage submitError={submitError} />
        <SuccessMessage submitSuccess={submitSuccess} />
        {!submitSuccess && (
          <form className="newsletter-inputs" name="subscribe" onSubmit={handleSubmit}>
            <label htmlFor="email" className={`${submitError ? 'has-error' : ''}`} aria-label="Email">
              <input
                type="email"
                id="newsletterEmail"
                disabled={submitting}
                name="newsletterEmail"
                placeholder="Enter your email address"
                onChange={handleChange}
                value={email || ''}
                required
              />
            </label>
            <button
              className={`${submitting ? 'button submitting' : 'button'}`}
              aria-label="Send"
              type="submit"
              disabled={submitting}
            >
              <span>{submitting ? 'Submitting' : 'Join'}</span>
            </button>
          </form>
        )}
      </div>
    </section>
  );
};
