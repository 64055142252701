import React, { useState, useRef } from 'react';
import { navigate } from 'gatsby';
import axios from 'axios';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import { Formik } from 'formik';
import { FormHeader, Fields } from 'components/Form';

// Form fields
const fields = [
  {
    type: 'input',
    inputType: 'text',
    name: 'firstName',
    label: 'First Name *',
    split: true,
  },
  {
    type: 'input',
    inputType: 'text',
    name: 'lastName',
    label: 'Last Name *',
    split: true,
  },
  {
    type: 'input',
    inputType: 'text',
    name: 'company',
    label: 'Company Name',
  },
  {
    type: 'input',
    inputType: 'email',
    name: 'emailAddress',
    label: 'Email *',
    split: true,
  },
  {
    type: 'input',
    inputType: 'tel',
    name: 'phone',
    label: 'Phone Number *',
    split: true,
  },
  {
    type: 'textarea',
    name: 'message',
    label: 'Message',
  },
];

// Form inital values
const initialValues = {
  firstName: '',
  lastName: '',
  company: '',
  phone: '',
  emailAddress: '',
  message: '',
};

// Handle form validation
const validate = values => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = 'Please provide your first name';
  } else if (!values.lastName) {
    errors.lastName = 'Please provide your last name';
  } else if (!values.emailAddress) {
    errors.emailAddress = 'Please provide an email address';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.emailAddress)) {
    errors.emailAddress = 'Please provide a valid email address';
  } else if (!values.phone) {
    errors.phone = 'Please provide a phone number';
  }
  return errors;
};

// Encode values to query string
const encode = data =>
  Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');

const ContactUs = () => {
  const formSection = useRef();
  const [success, setSuccess] = useState(false);

  // Handle form submit
  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      // Hide previous success message
      setSuccess(false);
      try {
        // Submit form to email service
        await axios.post(
          'https://a5w3o6c2j1.execute-api.ap-southeast-2.amazonaws.com/dev?format=json',
          encode({
            ...values,
            _to: '938d36a79cb031187fc31dd264fc9e2233163f09',
            _sender: 'Yordar',
            _formname: 'Contact Us',
            _replyTo: values.emailAddress,
          }),
          {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          }
        );
        // Add user to active campaign
        await axios.get(
          `/api/active-campaign?${encode({
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.emailAddress,
            phone: values.phone,
            list: 3,
            tag: 5,
          })}`
        );
        // Send to success page
        navigate('/thank-you');
      } catch (error) {
        // Usually fails if user is already in system
        console.error('Error adding to Active Campaign', error);
      }
      // Reset form values
      resetForm(initialValues);
      // Show success message
      setSuccess(true);
      scrollIntoView(formSection.current, { behavior: 'smooth', block: 'start' });
    } catch (error) {
      console.error('There was a problem submitting your form! Try again or reload the page :)', error);
    }
    // Set submitting as false
    setSubmitting(false);
  };

  return (
    <div className="form-section contact-us-form" ref={formSection}>
      <FormHeader title="Leave us a message" />
      {success && <p className="success">Thank you, one of our team will be in touch soon</p>}
      <Formik initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <form className="form" name="contact-us" method="POST" onSubmit={handleSubmit}>
            <Fields
              fields={fields}
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <button type="submit" className="button" disabled={isSubmitting}>
              {isSubmitting ? 'Sending...' : 'Send'}
            </button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default ContactUs;
