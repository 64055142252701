import React, { useState, useEffect } from 'react';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { Button } from './Button';
import { WithGoogleApi } from './WithGoogleApi';
import acceptedFilters from '../utils/acceptedFilters';
import './SearchBar.scss';

const SESSION_KEY = 'yordarLocationPreference';

const SearchBar = ({ buttonTheme, buttonText = 'Find Suppliers', location, categoryGroup = 'office-catering' }) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');
  const [address, setAddress] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const urlParam = window.location.pathname.split('/')[1];
  const hasFilter = acceptedFilters.includes(urlParam);

  useEffect(() => {
    if (address && value === address) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [value, address]);

  useEffect(() => {
    setLoading(false);
    // Get values from local storage on mount and set to state
    const savedLocation = localStorage.getItem(SESSION_KEY) ? JSON.parse(localStorage.getItem(SESSION_KEY)) : null;
    if (savedLocation) {
      setValue(savedLocation.address);
      setAddress(savedLocation.address);
    }
  }, []);

  const humanize = param =>
    param
      .split('-')
      .map(word => `${word[0].toUpperCase()}${word.slice(1)}`)
      .join()
      .replace(/,/g, ' ');

  const onSearchSubmit = async val => {
    try {
      const results = await geocodeByAddress(val);
      const placeObject = results[0];

      const { address_components: addressComponents } = placeObject;

      // Get values from google places address components array
      const streetNumType = 'street_number';
      const streetNameType = 'route';
      const suburbType = 'locality';
      const stateType = 'administrative_area_level_1';
      const suburbComp = addressComponents.find(comp => comp.types.includes(suburbType));
      const stateComp = addressComponents.find(comp => comp.types.includes(stateType));
      const streetNumComp = addressComponents.find(comp => comp.types.includes(streetNumType));
      const streetNameComp = addressComponents.find(comp => comp.types.includes(streetNameType));

      // Save values to state
      setLoading(true);
      setValue(val);

      // Only save street address is street name is return
      // Handle logic to include street number if exists
      let streetAddressValue = null;
      if (streetNameComp) {
        streetAddressValue = streetNumComp
          ? `${streetNumComp.long_name} ${streetNameComp.long_name}`
          : streetNameComp.long_name;
      }

      // Save values to local storage

      localStorage.setItem(
        SESSION_KEY,
        JSON.stringify({
          address: val,
        })
      );

      // Create search query string - needs refactor

      // Builds a query string consisting of:
      // -----------------------------------------------------
      // - suburb
      // - state
      // - street_address (if exists)
      // - category_group (if home deliveries page)

      const paramatize = string =>
        string
          .split(' ')
          .map(word => `${word[0].toUpperCase()}${word.slice(1)}`)
          .join('-');

      localStorage.setItem(
        'addressComponents',
        JSON.stringify({
          ...(streetAddressValue && { street: streetAddressValue }),
          suburb: paramatize(suburbComp.short_name),
          state: stateComp.short_name,
        })
      );

      const shortAddress = `${stateComp.short_name}/${paramatize(suburbComp.short_name)}`;
      const queryParams = [
        streetAddressValue && `street_address=${streetAddressValue}`,
        hasFilter && `filters=${urlParam}`,
      ].filter(Boolean);
      const queryString = `${shortAddress}${queryParams.length ? `?${queryParams.join('&')}` : ''}`;
      // Push user to app on address selection
      window.location.href = `${process.env.GATSBY_NEXT_APP_URL}/search/${categoryGroup}/${queryString}`;
    } catch (error) {
      console.log('Error fetching address', error);
    }
  };

  const handleChange = val => {
    setValue(val);
  };

  const handleFocus = () => {
    setValue('');
  };

  const handleBlur = () => {
    setValue(address || '');
  };

  const handleClick = event => {
    event.preventDefault();
    onSearchSubmit(value);
  };

  const handleSelect = val => {
    setAddress(val);
    onSearchSubmit(val);
  };

  const searchOptions = {
    componentRestrictions: { country: 'au' },
    types: ['geocode'],
  };

  const label = 'Enter street address, suburb or city';
  return (
    <div className={!isValid && value && value.length > 0 ? 'searchbar hide-button' : 'searchbar'}>
      <PlacesAutocomplete
        searchOptions={searchOptions}
        onChange={handleChange}
        onSelect={handleSelect}
        value={value || ''}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading: loadingSuggestions }) => (
          <div className="animate-label">
            <input
              onFocus={handleFocus}
              {...getInputProps({
                placeholder: label,
                className: 'location-search-input',
                onBlur: handleBlur,
              })}
            />
            <span>{label}</span>
            {(suggestions.length > 0 || loadingSuggestions) && (
              <div className="autocomplete-dropdown-container">
                {loadingSuggestions && <div className="autocomplete-dropdown-item">Loading...</div>}
                {suggestions.map(suggestion => {
                  const className = `autocomplete-dropdown-item ${suggestion.active ? 'active' : ''}`;
                  return (
                    <div {...getSuggestionItemProps(suggestion, { className })}>
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </PlacesAutocomplete>
      <Button to="" disabled={loading || !isValid} theme={buttonTheme} onClick={handleClick}>
        {loading ? <span className="searchbar-loading" /> : null}
        {loading ? '....' : buttonText}
      </Button>
      <p className="enter-address-tooltip">{`Please enter an address to view ${
        hasFilter ? ` ${humanize(urlParam)} ` : ''
      }Suppliers`}</p>
    </div>
  );
};

export default WithGoogleApi(SearchBar);
