import React from 'react';
import { Link, SectionHeader } from 'components';
import './OtherLocations.scss';

const OtherLocations = ({ title, tagline, otherAreas = [] }) => (
  <section className="other-locations">
    <div className="wrapper">
      <SectionHeader title={title} tagline={tagline} taglineTag="h3" />
      <div className="other-locations-list">
        {otherAreas.map(area => (
          <Link key={area.uid} to={`/office-catering/${area.uid}`}>
            {area.data.suburb.text}
          </Link>
        ))}
      </div>
    </div>
  </section>
);

export default OtherLocations;
