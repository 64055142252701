import React from 'react';
import { Link } from './Link';

export const Button = props => {
  const { to, title, children, theme = 'primary', outline, disabled, onClick, className } = props;
  return (
    <Link
      to={to}
      title={title || ''}
      className={`button ${theme} ${outline ? 'outline' : ''} ${className || ''}`}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </Link>
  );
};
