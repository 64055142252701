import React from 'react';
import { Image, Link, Carousel } from '../../components';
import './styles.scss';

const getTag = type => {
  switch (type) {
    case 'heading1':
      return 'h1';
    case 'heading2':
      return 'h2';
    case 'heading3':
      return 'h3';
    case 'heading4':
      return 'h4';
    case 'heading5':
      return 'h5';
    default:
      return 'span';
  }
};

const Column = ({ photography, image, icon, link, link_text: linkText, title, content }) => {
  const titleRaw = title && title.raw && title.raw[0];
  const titleType = titleRaw ? titleRaw.type : null;
  const titleText = titleRaw ? titleRaw.text : null;
  const linkUrl = link && link.url;
  const buttonText = linkText && linkText.text;
  const Container = linkUrl ? Link : 'div';
  const TitleTag = getTag(titleType);

  const showHubSpotChat = event => {
    event.preventDefault();
    const { HubSpotConversations } = window;
    if (HubSpotConversations) HubSpotConversations.widget.open();
  };

  const toggleHubSpot = linkUrl && linkUrl.indexOf('intercom') !== -1;

  return (
    <Container
      to={linkUrl || null}
      onClick={toggleHubSpot ? showHubSpotChat : () => {}}
      className={`columns-section-column ${icon && icon.url ? 'with-icon' : ''}`}
    >
      {image && (image.localFile || image.url) && <Image image={photography || image} alt={titleText} />}
      {icon && icon.url && <img className="columns-section-column-icon" src={icon.url} alt={titleText} />}
      {titleText && <TitleTag className="columns-section-column-title">{titleText}</TitleTag>}
      {content && content.html && (
        <div className="columns-section-column-content" dangerouslySetInnerHTML={{ __html: content.html }} />
      )}
      {linkUrl && buttonText && (
        <span className="button" to={linkUrl}>
          {buttonText}
        </span>
      )}
    </Container>
  );
};

const SliderContainer = ({ useSlider, children }) => {
  const className = 'columns-section-grid';

  const responsive = {
    0: {
      items: 1,
    },
    769: {
      items: 2,
    },
  };

  if (useSlider === 'Yes') {
    return (
      <Carousel
        containerClassName={className}
        enabledBetween={[0, 1024]}
        responsive={responsive}
        preventEventOnTouchMove={false}
        autoHeight
        buttonsDisabled
        infinite
      >
        {children}
      </Carousel>
    );
  }

  return <div className={className}>{children}</div>;
};

const Columns = ({ data }) => {
  const { primary, items } = data;
  const { title, use_slider: useSlider } = primary;
  return (
    <section className={`columns-section ${useSlider === 'Yes' ? 'has-slider' : ''}`}>
      <div className="wrapper">
        {title && title.text && <span className="columns-section-title">{title.text}</span>}
        <SliderContainer useSlider={useSlider}>
          {items.map(item => (
            <Column key={item.title.text} {...item} />
          ))}
        </SliderContainer>
      </div>
    </section>
  );
};

export default Columns;
