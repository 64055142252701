import React, { useState, useEffect } from 'react';
import { Carousel, Image, Link } from 'components';
import './ListingGrid.scss';

export const ListingGrid = props => (
  <section className="listing-grid">
    <ListingGridBody {...props} />
  </section>
);

const carouselResponsive = {
  0: { items: 1 },
  600: { items: 2 },
};

const ListingGridBody = ({ listings, loading, error }) => {
  const [listingsState, setListingsState] = useState(listings);

  useEffect(() => {
    setListingsState(listings);
  }, [listings]);

  if (error) return <p>Something went wrong</p>;
  if (loading) {
    return (
      <section className="listing-grid">
        <Carousel
          containerClassName="listing-grid-items loading"
          responsive={carouselResponsive}
          enabledBetween={[0, 769]}
          buttonsDisabled
          infinite
        >
          {listingsState.map(listing => (
            <div key={listing} className="listing-grid-item">
              <div className="listing-grid-item-image">
                <Image />
              </div>
              <div className="listing-grid-item-body">
                <span className="listing-grid-item-name" />
                <div className="listing-grid-item-meta" />
              </div>
            </div>
          ))}
        </Carousel>
      </section>
    );
  }
  return (
    <section className="listing-grid">
      <Carousel
        containerClassName="listing-grid-items"
        responsive={carouselResponsive}
        enabledBetween={[0, 769]}
        buttonsDisabled
        infinite
      >
        {listingsState.map(listing => (
          <Link
            key={listing.name}
            to={`${process.env.GATSBY_NEXT_APP_URL}/show/${listing.slug}`}
            className="listing-grid-item"
          >
            <div className="listing-grid-item-image">
              <Image
                image={{
                  url: `https://res.cloudinary.com/yordar-p/image/upload/c_fill,h_600,q_auto,fl_lossy,f_auto,w_600/${listing.image_id}`,
                  alt: `${listing.name} image`,
                }}
              />
              {listing.is_new && <span className="listing-grid-item-label">New</span>}
              <SupplierIcons items={listing.tooltip} />
              {listing.min_order && listing.min_order !== 'Unknown' && (
                <div className="listing-grid-item-min">
                  Min Order <strong>{listing.min_order} </strong>
                </div>
              )}
            </div>
            <div className="listing-grid-item-body">
              <span className="listing-grid-item-name">{listing.name}</span>
              <SupplierMeta delivery={listing.delivery_fee} leadTime={listing.lead_time} />
            </div>
          </Link>
        ))}
      </Carousel>
    </section>
  );
};

const SupplierIcons = ({ items }) => {
  if (!items || items.length === 0) return null;
  const iconCount = items.length;
  return (
    <div className="supplier-icons">
      <div className={`supplier-icon icon icon-${items[0].icon}`}>{items[0].label}</div>
      {iconCount > 1 && <div className="supplier-icon show-count">+{iconCount - 1}</div>}
    </div>
  );
};

const SupplierMeta = ({ delivery, leadTime }) => {
  if (!delivery && (!leadTime || leadTime === 'Unknown')) return null;
  return (
    <div className="listing-grid-item-meta">
      {delivery && <span className="listing-grid-item-meta-item">{delivery}</span>}
      {leadTime && leadTime !== 'Unknown' && <span className="listing-grid-item-meta-item">Lead Time {leadTime}</span>}
    </div>
  );
};
