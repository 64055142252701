function setupHubspotChat() {
  if (typeof window !== 'undefined') {
    window.hsConversationsSettings = {
      loadImmediately: false,
    };
    async function fetchHubSpotToken() {
      const response = await fetch('https://app.yordar.com.au/api/hubspot.json', { credentials: 'include' });
      const data = await response.json();
      return data;
    }

    if (typeof window !== 'undefined') {
      async function onConversationsAPIReady() {
        const hubSpotAuthInfo = await fetchHubSpotToken();
        window.hsConversationsSettings = {
          ...(hubSpotAuthInfo.email && { identificationEmail: hubSpotAuthInfo.email }),
          ...(hubSpotAuthInfo.token && { identificationToken: hubSpotAuthInfo.token }),
        };
        window.HubSpotConversations.widget.load();
      }
      if (window.HubSpotConversations) {
        onConversationsAPIReady();
      } else {
        window.hsConversationsOnReady = [onConversationsAPIReady];
      }
    }
  }
}
export default setupHubspotChat;
