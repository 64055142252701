import React from 'react';
import { BulkDiscount, ContactUs } from 'components/Form';
import './styles.scss';

const FormComponent = ({ type, location }) => {
  if (type === 'Bulk discount') return <BulkDiscount />;
  if (type === 'Contact us with map') return <ContactUs />;
  return <p>Contact us</p>;
};

const TextBlock = ({ data }) => {
  const { primary } = data;
  const { form_type: form } = primary;

  if (!form) return null;

  return (
    <section className="form-block">
      <div className="wrapper">
        <FormComponent type={form} />
      </div>
    </section>
  );
};

export default TextBlock;
