import React from 'react';
import { Tagline } from 'components';
import './SectionHeader.scss';

export const SectionHeader = ({ title, TitleTag = 'h2', tagline, TaglineTag = 'span', leftAlign }) => (
  <div className={`section-header ${leftAlign ? 'left-align' : ''}`}>
    <Tagline Tag={TaglineTag} tagline={tagline} />
    {title && <TitleTag className="section-header-title">{title}</TitleTag>}
  </div>
);
