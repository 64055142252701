/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import './GetStartedForm.scss';

export const SupplierSignUp = () => {
  const [email, setEmail] = useState(null);

  const handleSubmit = async event => {
    event.preventDefault();
    window.location.href = `https://yordar.com.au/supplier-registration?email=${email}`;
  };

  const handleChange = event => {
    setEmail(event.target.value);
  };

  return (
    <div className="get-started-form">
      <form className="newsletter-inputs" name="subscribe" onSubmit={handleSubmit}>
        <label htmlFor="email">Email Address</label>
        <input
          type="email"
          id="email"
          name="email"
          placeholder="Enter your email address"
          onChange={handleChange}
          value={email || ''}
          required
        />
        <button className="button" type="submit">
          Join Yordar
        </button>
      </form>
    </div>
  );
};
