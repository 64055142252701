import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Image, Link } from '../../components';
import './styles.scss';

const areasQuery = graphql`
  query {
    ...GlobalAreasFragment
  }
`;

const AreasCovered = ({ data, location }) => {
  const { primary } = data;
  const { title, custom_class: customClass } = primary;

  const categoryPages = [
    { pathname: 'milk', replacement: 'office-milk' },
    { pathname: 'fruit', replacement: 'office-fruit' },
    { pathname: 'snacks', replacement: 'office-snacks-pantry' },
  ];

  const getLink = link => {
    if (!link || !link.url) return null;
    let replacementLink;
    categoryPages.forEach(page => {
      if (location && location.pathname.includes(page.pathname)) {
        replacementLink = link.url.replace('office-catering', page.replacement);
      }
    });
    return replacementLink || link.url;
  };

  return (
    <StaticQuery
      query={areasQuery}
      render={({ prismicSettings }) => {
        const { data: queryData } = prismicSettings;
        const { areas_covered: areas = [] } = queryData;
        return (
          <section className={`areas-covered ${customClass ? customClass.text : ''}`}>
            <div className="wrapper">
              <h3 className="areas-covered-title">{title.text}</h3>
              <div className="areas-covered-grid">
                {areas.map(area => (
                  <Area key={area.title.text} {...area} link={getLink(area.link)} />
                ))}
              </div>
            </div>
          </section>
        );
      }}
    />
  );
};

const Area = ({ image, illustration, title, link }) => (
  <Link to={link} className="areas-covered-item">
    <Image image={illustration} />
    <span className="areas-covered-content">
      <h3>{title.text}</h3>
    </span>
  </Link>
);

export default AreasCovered;
